import React from "react";
import SEO from "../components/Seo/seo";
import Layout from "../components/Layout/Layout";
import FooterComponentCentered from "../components/Home/Footer/FooterComponentCentered";
import FormComponent from "../components/Form/FormComponent";


const ContactSubpage = () => {

  return (
    <Layout>
      <SEO title="Contact" />
      <FormComponent />
      <FooterComponentCentered />
    </Layout>
  )
}

export default ContactSubpage;