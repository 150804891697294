import React, { useState } from "react";
import styled from 'styled-components';
import { theme } from '../../utils/theme';
import Layout from "../Layout/Layout";
import emailjs from 'emailjs-com';
import { Link } from "gatsby";


// ---- Form Styles ----

const StyledFormContainer = styled.div`
${theme.flex.centeredColumn};
min-height: 100vh;

h2 {
    font-weight: 300;
}

a {
  color: #c8c7e2;
}

${theme.media.desktop} {
  h2 {
    font-size: 1.2rem;
    text-align: center;
    padding: 5%;
  }
}

${theme.media.mobile} {
  height: 100%;
}
`
const StyledMonit = styled.h1`
  ${theme.media.mobile} {
    margin-top: 50%;
    font-size: 1rem;
  }
`
const StyledHomeButton = styled(Link)`
  ${theme.buttonMain};
  ${theme.font.antic};
  font-weight: 600;
  position: absolute;
  bottom: ${props => props.centered === 'true' ? '30%' : '0'};
  left: ${props => props.centered === 'true' ? '50%; transform: translateX(-50%)' : '50px'};

${theme.media.mobile} {
  position: ${props => props.centered === 'true' ? 'absolute' : 'static'};
  margin: ${props => props.centered === 'true' ? '0' : '100px 0'};
  bottom: ${props => props.centered === 'true' ? '50%' : '50px'};
}
`
const StyledFormWrapper = styled.div`
${theme.flex.centeredColumn};
margin-bottom: 5%;
text-align: center;
padding: 50px;
position: relative;

  input, textarea {
      width: 50vw;
      height: 80px;
      margin-bottom: 30px;
      border-radius: 15px;
      border: none;
      padding-left: 20px;
      ${theme.font.antic};
      font-weight: bolder;
      font-size: 1.2rem;
      line-height: 1;
      outline-style: inherit;
      background: ${theme.colors.primary};

      &::placeholder {
        color: ${theme.colors.secondary};
        opacity: .8;
      }

      ${theme.media.mobile} {
        width: 100%;
        height: 60px;
        padding: 25px;
      }
}

  textarea {
      padding-top: 15px;
      min-height: 30vh;
      overflow: hidden;
  }

  ${theme.media.mobile} {
  padding: 25px;
}
`
const StyledSubmit = styled.div`

input {
  width: 200px;
  ${theme.buttonMain};
  margin: 0;
}
`

const FormComponent = () => {

  const [send, setSend] = useState('true');

  const handleSubmit = e => {
    e.preventDefault();
    setSend('false');

    console.log(e.target);

    emailjs.sendForm('smtp_server', 'template_tINseOrR', e.target, 'user_fdNB1uyqdZQdvgpGHkgen')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  return (

    <Layout>

      <StyledFormContainer>

        {(send === 'true') ? <h2>Contact us sending email to <a href="mailto:info@materiei.com" target="blank" aria-label="mailto" >info@materiei.com</a> or simply use form below.</h2> : ""}

        {(send === 'true') ? <StyledFormWrapper>

          <form id="materiei-mail" onSubmit={handleSubmit}>
            <input
              id="name"
              type="text"
              name="user_name"
              placeholder='Name'
              aria-label="name"
              required
            />

            <input
              id="email"
              type="email"
              name="user_email"
              placeholder='Email'
              aria-label="email"
              required
            />

            <input
              id="subject"
              type="text"
              name="subject"
              placeholder='Subject'
              aria-label="subject"
              required
            />

            <textarea
              id="textAr"
              name="message"
              cols="30"
              placeholder='Your message'
              aria-label="message"
              required
            />

            <StyledSubmit>
              <input type="submit" value="Send" />
            </StyledSubmit>

          </form>

          <StyledHomeButton to="/" aria-label="home">Home</StyledHomeButton>

        </StyledFormWrapper>
          :
          <StyledMonit>
            Your message has been sent !
            <StyledHomeButton centered={'true'} to="/" aria-label="home">Home</StyledHomeButton>
          </StyledMonit>}

      </StyledFormContainer>

    </Layout>
  )
}

export default FormComponent;